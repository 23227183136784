<template>
  <div v-if="$apollo.queries.work.loading" class="text-4xl text-center mt-10 text-gray-600 italic">Loading...</div>
  <div v-else-if="work">
    <div class="border-b-2 border-black">
      <div class="container main-wrapper mx-auto">
        <!-- if there is a prevRoute, provide a back link to the search results -->
        <router-link v-if="prevRouteIsSearch" :to="prevRoute" class="back-to-search hover:underline">&lt; Back to Search Results</router-link>

        <h1 class="text-4xl mt-6 work-title">{{ work.title }}</h1>
        <div v-if="isAuthenticated && isAdmin">
          <a :href="`/mcih-admin/folio/works/${uuid}/update/`">Edit Work</a>
        </div>
        <div class="cursor-pointer flex my-2" @click.prevent="showArtworkInfo = !showArtworkInfo">
          <button class="text-lg uppercase tracking-wider" id="showArtworkInfoButton" :aria-expanded="showArtworkInfo.toString()" aria-controls="artworkInfo">Artwork Info</button>
          <svg v-if="showArtworkInfo" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </div>
    </div>
    <div class="container mx-auto p-5 border-b-2 border-black mb-6 flex flex-wrap art-detail-container" v-if="showArtworkInfo" id="artworkInfo" role="region" aria-labelledby="showArtworkInfoButton">
      <div class="w-1/2">
        <div class="flex mb-2" v-if="accessionNumber"><div class="artwork-info__label">Accession Number</div><div class="text-sm font-mono">{{ accessionNumber }}</div></div>
        <div class="flex mb-2" v-if="artist"><div class="artwork-info__label">Artist</div><div class="artwork-info__text">{{ artist }}</div></div>
        <div class="flex mb-2" v-if="date"><div class="artwork-info__label">Date</div><div class="artwork-info__text">{{ date }}</div></div>
        <div class="flex mb-2" v-if="location"><div class="artwork-info__label">Location</div><div class="artwork-info__text">{{ location }}</div></div>
        <div class="flex mb-2" v-if="type"><div class="artwork-info__label">Type</div><div class="artwork-info__text">{{ type }} {{ sequentialOrder ? `(${sequentialOrder}${folioSide})` : null }}</div></div>
        <div class="flex mb-2" v-if="zoteroPublications.length > 0">
          <div class="flex mb-2">
            <div class="artwork-info__label">References</div>
            <div class="">
              <div v-for="reference in zoteroPublications" :key="reference.id" class="flex-reference">
                <a :href="'/bibliography/#' + reference.zotKey">{{ getNameAndYearCitation(reference.zotCreator,reference.zotDate) }} </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/2">
        <div class="flex mb-2"><div class="uppercase w-28 tracking-wider my-auto text-sm artwork-info__label">Collection</div><div class="artwork-info__text">
          <a v-if="link" :href="link" target="_blank" class="hover:underline text-blue-600">{{ collection }}</a>
          <template v-else>{{ collection }}</template>
        </div></div>
        <div class="flex mb-2" v-if="style"><div class="uppercase w-28 tracking-wider my-auto text-sm artwork-info__label">Style</div><div class="artwork-info__text">{{ style }}</div></div>
        <div class="flex mb-2" v-if="mediums"><div class="uppercase w-28 tracking-wider my-auto text-sm artwork-info__label">Medium</div><div class="artwork-info__text">{{ mediums }}</div></div>
        <div class="flex mb-2" v-if="dimensions"><div class="uppercase w-28 tracking-wider my-auto text-sm artwork-info__label" >Dimensions</div><div class="artwork-info__text">{{ dimensions }}</div></div>
      </div>
      <div class="w-100" v-if="parentWorkRelatedWorks.length > 0">
        <div class="flex mb-2">
          <div class="artwork-info__label">Related Works</div>
          <div class="artwork-info__text">
            <div class="related-through">
              <span class="related-through-prefix" v-if="!startsWithFrom(parentWork.name)">
                Related through
              </span>
              <span class="parent-work-title">{{ parentWork.name }}</span>
            </div>
            <div v-for="relatedWork in parentWorkRelatedWorks" :key="relatedWork.id" class="flex related-work">
              <router-link :to="{ name: 'detail-view', params: { identifier: relatedWork.slug }}" class="block hover:underline text-blue-600 related-work-title">
                {{ relatedWork.title }}
              </router-link>
              <span class="text-xs font-mono my-auto ml-2">{{ relatedWork.accessionNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mirador-vue
      v-if="iiifManifestUrl"
      :config="miradorConfig"
      :pluginNames="miradorPlugins"
      :manifestUrl="iiifManifestUrl"
    />
    <img class="w-full border-b-2 border-black" :src="work.imageUrl" v-else/>

    <div class="container mx-auto mt-8">
      <div class="text-center mb-8">
        <span v-for="(analysis, index) in analyses" :key="index" class="analysis border-black border-2 px-4 py-2 tracking-wider mr-2 hover:bg-yellow-400 cursor-pointer" :class="selectedAnalysis === analysis.uuid ? 'bg-yellow-300' : null" @click.prevent="selectedAnalysis = analysis.uuid; this.updateAnalysis(analysis); this.updateURL(analysis.uuid);">
          Analysis {{ index + 1 }} ({{ analysis.visiblecolorSet.length }})
        </span>
      </div>
      <div class="mt-16 mb-16 selected-analysis" v-if="selectedAnalysis">
        <div v-if="isAuthenticated && isAdmin">
          <a :href="`/mcih-admin/analyses/${currentAnalysis.uuid}/`">Edit Analysis</a>
        </div>
        <div class="text-xl analysis-analysts">{{ analysts }}</div>
        <div class="text-lg analysis-description">{{ currentAnalysis.description }}</div>
        <div class="text-lg text-gray-600 analysis-date">{{ currentAnalysis.startDate }} thru {{ currentAnalysis.endDate }}</div>
        <div class="w-100" v-if="currentAnalysis.zoteroPublications.length > 0">
          <div class="text-lg text-gray-600 analysis-citations">
            <div v-for="reference in currentAnalysis.zoteroPublications" :key="reference.id" class="flex-analysis-reference">
              Source: <a :href="'/bibliography/#' + reference.zotKey"> {{ getNameAndYearCitation(reference.zotCreator,reference.zotDate) }} </a>
            </div>
          </div>
        </div>
        <table class="mt-4 w-full mb-16" id="analysis-colors-table">
          <thead class="uppercase tracking-wider border-b-2 border-black text-left"><tr>
            <th class="align-top">Color</th>
            <th class="align-top">Pigments</th>
            <th class="align-top">
              Elements
              <div class="mt-1 flex items-baseline capitalize text-sm">
                <span class="pr-1">Major</span>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="svg-inline--fa fa-star fa-w-18 w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
                <span class="pl-2 pr-1">Minor</span>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="svg-inline--fa fa-star fa-w-18 w-4 text-gray-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
                <span class="pl-2 pr-1">Trace</span>
                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" class="svg-inline--fa fa-star fa-w-18 w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
              </div>
            </th>
            <th class="align-top">Analysis Point</th>
            <th class="align-top">Location / Method</th>
          </tr></thead>
          <tbody>
            <tr class="analysis-color border-b-2 border-black" v-for="(vc, index) in currentAnalysis.visiblecolorSet" :key="index">
              <td class="py-4 pr-4 align-top">
                <div v-if="vc.substrate" class="mt-2 uppercase text-xs text-gray-500 tracking-wide">Substrate</div>
                <VisibleColor v-else-if="vc.color" :color="vc.color" />
              </td>
              <td class="py-4 pr-4 align-top">
                <div v-for="(pigment, pigIndex) in pigments(vc)" :key="pigIndex" class="flex items-baseline">
                  <span class="mr-2">{{ pigment.name }}</span>
                  <svg v-if="pigment.certainty === 'A_3'" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="signal-1" class="svg-inline--fa fa-signal-1 fa-w-20 w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><g class="fa-group"><path class="fa-secondary" fill="currentColor" d="M216 288h-48a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V304a16 16 0 0 0-16-16zm128-96h-48a16 16 0 0 0-16 16v288a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V208a16 16 0 0 0-16-16zM600 0h-48a16 16 0 0 0-16 16v480a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16zM472 96h-48a16 16 0 0 0-16 16v384a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V112a16 16 0 0 0-16-16z" opacity="0.4"></path><path class="fa-primary" fill="currentColor" d="M104 400v96a16 16 0 0 1-16 16H40a16 16 0 0 1-16-16v-96a16 16 0 0 1 16-16h48a16 16 0 0 1 16 16z"></path></g></svg>
                  <svg v-if="pigment.certainty === 'A_2'" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="signal-3" class="svg-inline--fa fa-signal-3 fa-w-20 w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><g class="fa-group"><path class="fa-secondary" fill="currentColor" d="M472 96h-48a16 16 0 0 0-16 16v384a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V112a16 16 0 0 0-16-16zM600 0h-48a16 16 0 0 0-16 16v480a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16z" opacity="0.4"></path><path class="fa-primary" fill="currentColor" d="M88 384H40a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-96a16 16 0 0 0-16-16zm256-192h-48a16 16 0 0 0-16 16v288a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V208a16 16 0 0 0-16-16zm-128 96h-48a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V304a16 16 0 0 0-16-16z"></path></g></svg>
                  <svg v-if="pigment.certainty === 'A_1'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="signal" class="svg-inline--fa fa-signal fa-w-20 w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M216 288h-48c-8.84 0-16 7.16-16 16v192c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V304c0-8.84-7.16-16-16-16zM88 384H40c-8.84 0-16 7.16-16 16v96c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16v-96c0-8.84-7.16-16-16-16zm256-192h-48c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zm128-96h-48c-8.84 0-16 7.16-16 16v384c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V112c0-8.84-7.16-16-16-16zM600 0h-48c-8.84 0-16 7.16-16 16v480c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V16c0-8.84-7.16-16-16-16z"></path></svg>
                </div>
              </td>
              <td class="py-4 pr-4 align-top">
                <div class="flex flex-wrap">
                  <span class="mr-3 flex items-baseline"  v-for="(element, elIndex) in elements(vc)" :key="elIndex">
                    <span class="pr-1">{{ element.element.symbol }}</span>
                    <svg v-if="element.amount === 'MAJOR'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="svg-inline--fa fa-star fa-w-18 w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
                    <svg v-if="element.amount === 'MINOR'" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="svg-inline--fa fa-star fa-w-18 w-4 text-gray-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
                    <svg v-if="element.amount === 'TRACE'" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" class="svg-inline--fa fa-star fa-w-18 w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                  </span>
                </div>
              </td>
              <!-- <td class="py-4 align-top"><div>{{ analysis_point(vc) }}</div></td> -->
              <td class="py-4 align-top"><div v-for="(analysisPoint, apIndex) in analysis_point(vc)" :key="apIndex">{{ analysisPoint.analysisPoint }}</div></td>
              <td class="py-4 align-top"><div v-for="(location, locIndex) in locations(vc)" :key="locIndex">{{ location.location }} - {{ location.method }}</div></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-16 mb-16" v-else><p class="text-xl text-center px-8 py-16 bg-gray-100 text-gray-500 border border-gray-200 rounded-2xl">Select an analysis to see details.</p></div>
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';

  import MiradorVue from '../components/MiradorVue.vue';
  import VisibleColor from '../components/VisibleColor.vue';
  import { inject } from 'vue'

  export default {
    props: {
      slug: String,
    },
    components: {
      VisibleColor,
      MiradorVue
    },
    data() {
      return {
        work: null,
        workTitle: '',
        showArtworkInfo: true,
        selectedAnalysis: null,
        miradorPlugins: [
          'miradorImageToolsPlugin',
          'catchPy'
        ],
        isAuthenticated: false,
        isAdmin: false,
        prevRoute: null
      };
    },
    setup() {
      const updateCiteType = inject('updateCiteType');
      updateCiteType('work');
      const updateWork = inject('updateWork');
      const updateAnalysis = inject('updateAnalysis');
      return {updateWork,updateAnalysis};
    },
    created(){
      this.isAuthenticated = window.isAuthenticated;
      this.isAdmin = window.isAdmin;
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevRoute = from;
      })
    },
    watch: {
      work: {
        handler(newWork, oldWork) {
          this.showArtworkInfo = true;
          this.selectedAnalysis = null;
          //load the selected analysis from the url if one is specified
          let urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has('selectedAnalysis')) {
            this.selectedAnalysis = urlParams.get('selectedAnalysis');
            //update footer data
            this.updateAnalysis(this.currentAnalysis);
          };

          // When the work data is loaded or updated, set the document title
          if (newWork && newWork.title) {
            this.workTitle = newWork.title; // Set a data property for the work title
            document.title = `${this.workTitle} - MCH`; // Update the document title with the work title
            //update footer data
            this.updateWork(newWork);
          }
        },
        deep: true, // In Vue 3, this is necessary to watch "deep" data, e.g., nested data such as arrays https://michaelnthiessen.com/how-to-watch-nested-data-vue
      },
    },
    computed: {
      prevRouteIsSearch() {
        if (this.prevRoute?.name === 'search-view') {
          return this.prevRoute.name === 'search-view' ? this.prevRoute : null;
        } else {
          return false;
        }
      },
      uuid() {
        return this.work.uuid;
      },
      link() {
        return this.work.link;
      },
      artist() {
        //here, as with below functions, the first half of the return statement prevents vue errors when
        // elements of work are null
        return this.work.artist && this.work.artist.name;
      },
      date() {
        return this.work.date && this.work.date.name;
      },
      location() {
        return this.work.location && this.work.location.name;
      },
      type() {
        return this.work.classification && this.work.classification.name;
      },
      sequentialOrder() {
        return this.work.sequentialOrder;
      },
      folioSide() {
        if (this.work && this.work.sequentialOrder === null) {
          return null;
        }
        if (this.work && this.work.folioSide === 'U') {
          return '';
        }
        return this.work.folioSide.toLowerCase().replace('_', '/');
      },
      accessionNumber() {
        return this.work.accessionNumber;
      },
      collection() {
        return this.work.repository && this.work.repository.name;
      },
      style() {
        return this.work.style && this.work.style.name;
      },
      mediums() {
        return this.work.mediums.map(m => m.name).join(', ');
      },
      dimensions() {
        return `${this.work.width} x ${this.work.height}`;
      },
      analyses() {
        return this.work.analysisSet;
      },
      currentAnalysis() {
        if (this.selectedAnalysis === null) {
          return null;
        }
        return this.analyses && this.analyses.reduce((map, analysis) => ({
          ...map,
          [analysis.uuid]: analysis,
        }), {})[this.selectedAnalysis];
      },
      analysts() {
        return this.currentAnalysis.analysts.map(a => a.name).join(', ');
      },
      relatedWorks() {
        return this.work.relatedWorks.edges.map(edge => ({...edge.node}));
      },
      parentWork() {
        return this.work.parentWork;
      },
      parentWorkRelatedWorks() {
        if (this.work && this.parentWork && Array.isArray(this.parentWork.relatedWorks)) {
          // filter to not include the current work
          return this.parentWork.relatedWorks.filter(relatedWork => relatedWork.id !== this.work.id);
        }
        return []; // return an empty array if parentWork or parentWork.relatedWorks is not available
      },
      iiifManifestUrl(){
        return this.work.iiifManifest;
      },
      miradorConfig(){
        return {
          id: 'mirador',
          window: {
            allowClose: false,
            allowFullScreen: true,
            allowMaximize: false,
            panels: {
              annotations: true
            },
            defaultSideBarPanel: 'info',
            sideBarOpenByDefault: false
          },
          windows: [
            {
              loadedManifest: this.iiifManifestUrl,
              canvasIndex: 2,
              thumbnailNavigationPosition: 'far-bottom',
            },
          ],
          workspace: {
            draggingEnabled: false,
            allowNewWindows: false,
            showZoomControls: true
          },
          workspaceControlPanel: {
            enabled: false
          },
          selectedTheme: 'mcih',
          themes:{
            mcih: {
              palette: {
                type: 'light',
                typography: {
                  fontFamily: [
                    'Agrandir',
                    'ui-sans-serif'
                  ]
                }
              }
            }
          },
          annotations: {
              htmlSanitizationRuleSet: 'iiif', // See src/lib/htmlRules.js for acceptable values
              filteredMotivations: ['oa:tagging', 'sc:painting', 'commenting', 'tagging'], // Not including 'oa:commenting' to filter out HAM annos
          }
        }
      },
      zoteroPublications() {
        return this.work.zoteroPublications;
      }
    },
    methods: {
      locations(visibleColor) {
        return visibleColor.visiblecoloranalysismethodologySet && visibleColor.visiblecoloranalysismethodologySet.map(a => ({location: a.locationDescription, method: a.analysisMethodology && a.analysisMethodology.acronym}));
      },
      pigments(visibleColor) {
        return visibleColor.visiblecolorpigmentSet && visibleColor.visiblecolorpigmentSet.map(a => ({ name: a.pigment.name, certainty: a.certaintyLevel }));
      },
      elements(visibleColor) {
        return visibleColor.visiblecolorelementSet && visibleColor.visiblecolorelementSet.map(a => ({ element: a.element, amount: a.amount }));
      },
      analysis_point(visibleColor){
        // return visibleColor.visiblecoloranalysismethodologySet.analysisPoint;
        return visibleColor.visiblecoloranalysismethodologySet && visibleColor.visiblecoloranalysismethodologySet.map(a => ({ analysisPoint: a.analysisPoint }));
        // return visibleColor.visiblecolorelementSet && visibleColor.visiblecolorelementSet.map(a => ({ analysisPoint: a.analysisPoint }));
      },
      startsWithFrom(name) {
        return name.startsWith("From");
      },
      // Constructs an Author, Year citation for a given Zotero publication
      getNameAndYearCitation(creatorString, date) {
        try {
          // Each creator object is a string formatted list of zero or more JSON objects representing authors
          // for each cited reference. We only need the name of the first author.
          // Example: [{<author 1 info>},{<author 2 info>},...]

          // Regex to match individual JSON objects in the string
          let creatorRegex = new RegExp('{.*?}', "g");

          // Correcting the single quotes to double quotes required by JSON format
          // Note: More precise replacements are needed to prevent field-internal apostrophes from being replaced
          let creatorStringCorrected = creatorString
            .replace(/', '/g, '", "')
            .replace(/': '/g, '": "')
            .replace(/': "/g, '": "')
            .replace(/'}/g, '"}')
            .replace(/{'/g, '{"');

          // Extract the first creator's JSON string
          let firstCreator = creatorRegex.exec(creatorStringCorrected)[0];
          let firstCreatorJSON = JSON.parse(firstCreator);

          // Determine the name field based on the available key ("lastName" or "name")
          let name = "";
          if (firstCreatorJSON.hasOwnProperty("lastName")) {
            name = firstCreatorJSON.lastName;
          } else if (firstCreatorJSON.hasOwnProperty("name")) {
            name = firstCreatorJSON.name;
          }

          // Count the total number of authors and append "et al" if there are three or more authors
          let authors = [...creatorString.matchAll(creatorRegex)];
          let authorCount = authors.length;
          if (authorCount >= 3) {
            name += " et al";
          }

          // Return the formatted citation
          return name + ", " + date;
        } catch (error) {
          console.error("Failed to parse creatorString or generate citation:", error);
          return "Unknown Author, " + date;
        }
      },
      updateURL(analysisID) {
        history.pushState(
          {},
          null,
          this.$route.path + '?' + 'selectedAnalysis=' + analysisID
        );
      }
    },
    apollo: {
      work: {
        query: gql`query Work ($slug: String!) {
          work(slug: $slug) {
            title
            slug
            id
            uuid
            imageUrl
            accessionNumber
            width
            height
            sequentialOrder
            folioSide
            artist {
              name
              description
            }
            link
            iiifManifest
            location {
              name
            }
            date {
              name
            }
            style {
              name
            }
            classification {
              name
            }
            mediums {
              name
            }
            repository {
              name
              description
            }
            relatedWorks {
              edges {
                node {
                  id
                  title
                  accessionNumber
                }
              }
            }
            parentWork {
              id
              name
              description
              relatedWorks {
                title
                id
                accessionNumber
                slug
              }
            }
            zoteroPublications {
              zotCreator
              zotDate
              zotKey
            }
            analysisSet {
              uuid
              startDate
              endDate
              description
              analysts {
                name
              }
              visiblecolorSet {
                description
                color {
                  name
                  hexCode
                  hexCodeLeftGradient
                  hexCodeRightGradient
                }
                substrate
                visiblecolorpigmentSet {
                  pigment {
                    name
                  }
                  certaintyLevel
                }
                visiblecolorelementSet {
                  element {
                    name
                    symbol
                  }
                  amount
                }
                visiblecoloranalysismethodologySet {
                  locationDescription
                  analysisPoint
                  analysisMethodology {
                    name
                    acronym
                    description
                  }
                  visibleColor {
                    color {
                      name
                    }
                  }
                }
              }
              zoteroPublications {
                zotCreator
                zotDate
                zotKey
                citation
              }
            }
          }
        }`,
        variables() {
          return {
            id: this.id,
            slug: this.slug
          };
        }
      }
    }
  }
</script>


<style>
  @import "https://unpkg.com/leaflet@1.6.0/dist/leaflet.css";

  #mirador-container {
    width: 100%;
    height: 800px;
    position: relative;
  }

  .artwork-info__label {
    @apply uppercase;
    @apply w-40;
    @apply tracking-wider;
    @apply my-auto;
    @apply text-sm;
    @apply mr-2;
  }
</style>
