<template>
  <router-link
    class="flex flex-col justify-between text-center border border-gray-700 w-40 h-40 hover:bg-blue-200 hover:border-blue-500 hover:shadow-lg"
    :to="{ name: 'search-view', query: { 'works[refinementList][analyses.elements][0]': element.symbol }}"
  >
    <span></span>
    <span class="flex flex-col">
      <span class="text-gray-700 font-mono element-atomicNumber">{{ element.atomicNumber }}</span>
      <span class="text-gray-900 text-4xl font-semibold mt-2 element-symbol">{{ element.symbol }}</span>
      <span class="text-gray-700 element-name">{{ element.name }}</span>
    </span>
    <span class="text-xs pb-1 text-gray-500 element-workCount">{{ element.workCount }} work{{ element.workCount === 1 ? '' : 's' }}</span>
  </router-link>
</template>

<script>
  export default {
    props: {
      element: Object,
    },
  };
</script>
