<template>
  <div id="footer" class="container mx-auto mt-20 main-wrapper">
    <hr class="footer-rule">
    <img class="footer-logo" :src="footerlogo" alt="Mapping Color in History Logo">
    <div id="navButton">
      <a href="/methodologies" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'methodologies-view' })">Methodologies</a>
    </div>
    <div id="navButton">
      <a href="/about" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'dynamic-view' }, 'about')">About MCH</a>
    </div>
    <div id="navButton">
      <a href="/bibliography" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'dynamic-view' }, 'bibliography')">Bibliography</a>
    </div>
    <div id="navButton">
      <a href="/process" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'dynamic-view' }, 'process')">Our Process</a>
    </div>

    <div v-if="citeType !== 'none'" id="citebutton">
      <button class="cite-button" id="page-cite-button" ref="pageCiteButton" @click="showCiteModal"><span class="lg-quo">&ldquo;</span> Cite</button>
    </div>
    <hr class="footer-rule copyright-rule">
    <p class="copyright">© 2024 Mapping Color in History. All rights reserved.  Mapping Color in History™ is a trademark of The President and Fellows of Harvard College.</p>


    <!-- Modal overlay -->
    <dialog id="modal-overlay" ref="modalOverlay" v-if="citeType !== 'none'" class="fixed inset-0" @click="closeCiteModal">
      <div id="cite-page-modal" ref="citePageModal" class="modal" @click.stop>
        <div class="modal-content bg-white p-6 rounded shadow-lg w-100 mx-auto z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fixed w-4/5">
            <span class="close absolute top-0 right-0 mt-2 mr-2 text-xl cursor-pointer close-cite" id="close-cite-page-modal" ref="closeCitePageModal" @click="closeCiteModal">&#10005;</span>
            <!-- cite work -->
            <h2 class="h3-title title-w-rule" v-if="citeType === 'work' || citeType === 'static' || citeType === 'methodologies'">Cite This Page</h2>
            
            <div class="cite-text" v-if="citeType === 'work' && work != null" id="citation" ref="workCitation">{{ artist }}, <i>{{ work.title }}</i> {{ date }}
              ({{ collection }} {{ accessionNumber }}) Accessed via Mapping Color in History, {{ today }}. {{  url  }}.</div>
            <div><button class="text-lg uppercase tracking-wider cite-button cite-button-smaller" v-if="citeType === 'work'" id="copy-work-cite-button" ref="copyWorkCiteButton" @click="copyCitation('workCitation')">Copy to Clipboard</button></div>
            <!-- cite analysis -->
  
            <h2 class="h3-title title-w-rule" v-if="citeType === 'work' && citeAnalysis && analysis.zoteroPublications.length > 0">Cite This Analysis</h2>
           
            <div class="cite-text" v-if="citeType === 'work' && analysis !== null" id="analysisCitation" ref="analysisCitation">{{  authorNames  }}. Analysis of <i>{{ work.title }}</i>, {{ artist }}  {{ date }}
              ({{ collection }} {{ accessionNumber }}) Accessed via Mapping Color in History, {{ today }}. {{  analysisURL  }}.
              Data from {{  zotCitation  }}
            </div>
            <div><button class="text-lg uppercase tracking-wider cite-button cite-button-smaller" v-if="citeType === 'work' && citeAnalysis" id="copy-analysis-cite-button" ref="copyAnalysisCiteButton" @click="copyCitation('analysisCitation')">Copy to Clipboard</button></div>
            <!-- cite mapping color -->
            <h2 class="h3-title title-w-rule" v-if="citeType === 'site'">Cite Mapping Color</h2>
            <div class="cite-text" v-if="citeType === 'site'" id="citation" ref="mcihCitation">Mapping Color in History. Accessed {{  today  }}. https://mappingcolor.fas.harvard.edu.</div>
            <div><button class="text-lg uppercase tracking-wider cite-button cite-button-smaller" v-if="citeType === 'site'" id="copy-mcih-cite-button" ref="copyMCIHCiteButton" @click="copyCitation('mcihCitation')">Copy to Clipboard</button></div>
            <!-- cite static page -->
            <div class="cite-text" v-if="citeType === 'static'  && page !== null" id="citation" ref="staticCitation">"{{  page.title  }}." Mapping Color in History. Accessed {{  today  }}. {{  url  }}.</div>
            <div><button class="text-lg uppercase tracking-wider cite-button cite-button-smaller" v-if="citeType === 'static'" id="copy-mcih-cite-button" ref="copyStaticCiteButton" @click="copyCitation('staticCitation')">Copy to Clipboard</button></div>
            <!-- cite methodologies, elements, and pigments -->
            <div class="cite-text" v-if="citeType === 'methodologies'" id="citation" ref="staticCitation">"Methodologies." Mapping Color in History. Accessed {{  today  }}. {{  url  }}.</div>
            <div class="cite-text" v-if="citeType === 'pigments'" id="citation" ref="staticCitation">"Pigments." Mapping Color in History. Accessed {{  today  }}. {{  url  }}.</div>
            <div class="cite-text" v-if="citeType === 'elements'" id="citation" ref="staticCitation">"Elements." Mapping Color in History. Accessed {{  today  }}. {{  url  }}.</div>
            <div><button class="text-lg uppercase tracking-wider cite-button cite-button-smaller" v-if="citeType === 'methodologies'" id="copy-mcih-cite-button" ref="copyStaticCiteButton" @click="copyCitation('staticCitation')">Copy to Clipboard</button></div>

          </div>
      </div>
    </dialog>
  </div>
</template>

<script>
import footerlogo from '@public/images/color/MCH_footer-logo.svg';

  export default {
    data() {
    return {
      footerlogo
    };
  },
    inject: [
      'citeType',
      'page',
      'work',
      'getAnalysis'
    ],
    computed: {
      today() {
        // Get current date
        var now = new Date();
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        return now.toLocaleString('default',options);
      },
      url() {
        return window.location.origin + this.$route.fullPath;
      },
      analysis() {
        return this.getAnalysis()
      },
      analysisURL() {
        //known issue, if the analysis is changed after opening a page from an analysis citation,
        // the selectedAnalysis parameter won't update. need a simple way to overwrite th
        // current url param.
        //without this check, you can get a URL with two selectedAnalysis parameters in it
        //TO DO: figure out how to update url params dynamically in a more vue like way
        if (this.url.includes("?selectedAnalysis")) {
          return this.url;
        } else {
          var newURL = this.url + "?selectedAnalysis=" + this.analysis.uuid;
          return newURL;
        }
      },
      citeAnalysis() {
        return this.getAnalysis() != null;
      },
      zotCitation() {
        //this comes as a div, so we need to extract its contents
        if (this.analysis.zoteroPublications.length > 0) {
          var citeDiv = document.createElement('div');
          citeDiv.innerHTML = this.analysis.zoteroPublications[0].citation;
          return citeDiv.textContent;
        } else {
          return "Missing Zotero Citation";
        }

      },
      artist() {
        //here, as with below functions, the first half of the return statement prevents vue errors when
        // elements of work are null
        return this.work.artist && this.work.artist.name;
      },
      date() {
        return this.work.date && this.work.date.name;
      },
      accessionNumber() {
        return this.work.accessionNumber;
      },
      collection() {
        return this.work.repository && this.work.repository.name;
      },
      authorNames() {
        var analysis = this.getAnalysis();
        if (analysis.zoteroPublications.length > 0) {
          return this.getAuthorNames(analysis.zoteroPublications[0].zotCreator)
        } else {
          return "Unknown Authors Due to Missing Citation"
        }

      }
    },
    methods: {
      showCiteModal() {
        this.$refs.modalOverlay.showModal();
      },
      closeCiteModal() {
        this.$refs.modalOverlay.close();
      },
      copyCitation(elementName) {
        navigator.clipboard.writeText(this.$refs[elementName].textContent);
      },
      nav(to, slug = null) {
        this.$emit('navigated');
        // the default search won't work from ?view=map, and need to handle ?view differently ?
        if(to.name == "search"){
          this.$router.push({ path: '/search', query: { view: 'thumb' }});
          return;
        }
        if(to.name == "map"){
          this.$router.push({ path: '/search', query: { view: 'map' }});
          return;
        }
        if (to.name === "dynamic-view") {
        // If slug is provided, navigate to the dynamic-view with the slug
          if (slug) {
            this.$router.push({ name: 'dynamic-view', params: { slug } });
            return;
            }
        }
        if (to.name === "home-view") {
          if (slug) {
            this.$router.push({ name: 'home-view', params: { slug } });
            return;
            }
        }
        if (this.$route.name !== to.name) {
          this.$router.push(to);
        }
      },
      // Gets the properly formatted author name for a zotero citation
      getAuthorNames(creatorString) {
        try {
          // Each creator object is a string formatted list of zero or more JSON objects representing authors
          // Example: [{<author 1 info>},{<author 2 info>},...]

          // Regex to match individual JSON objects in the string
          let creatorRegex = new RegExp('{.*?}', "g");

          // Correcting the single quotes to double quotes required by JSON format
          // Note: More precise replacements are needed to prevent field-internal apostrophes from being replaced
          let creatorStringCorrected = creatorString
            .replace(/', '/g, '", "')
            .replace(/': '/g, '": "')
            .replace(/': "/g, '": "')
            .replace(/'}/g, '"}')
            .replace(/{'/g, '{"');

          let names = "";
          let authors = [...creatorStringCorrected.matchAll(creatorRegex)];
          // Extract each creator's JSON string
          // We only need to invert the first name in multi-author bibliography citations.
          let authorIndex = 0;
          authors.forEach((author) => {
              let creatorJSON = JSON.parse(author);

              // Determine the name field based on the available key ("lastName" or "name")
              let name = "";
              if (creatorJSON.hasOwnProperty("lastName")) {
                name = creatorJSON.lastName;
                if (authorIndex == 0) {
                  if (creatorJSON.hasOwnProperty("lastName") && creatorJSON.firstName.length > 0) {
                    name += ", " + creatorJSON.firstName
                  };
                } else {
                  if (creatorJSON.hasOwnProperty("lastName") && creatorJSON.firstName.length > 0) {
                    name = creatorJSON.firstName + " " + name
                  };
                }

              } else if (creatorJSON.hasOwnProperty("name")) {
                //using a raw name field is very likely to result in an incorrecct citation
                name = creatorJSON.name;
              }
              names += name
              //If we're not done yet, add a comma before the next name
              if (authorIndex < authors.length-1) {
                names += ", "
              }
              authorIndex += 1;
            }
          )

          // Return the formatted names
          return names;
        } catch (error) {
          console.error("Failed to parse creatorString to generate analysis citation:", error);
          return "Unknown Authors";
        }
      }
    }
  }

</script>
