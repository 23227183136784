<template>
    <div class="collapsible-panel">
      <button @click="toggleVisibility" class="btn btn-primary button">
        <div class="button-container">
          <strong>{{ label }}</strong>
          <svg class="icon" viewBox="0 0 10 10">
            <path v-if="isVisible" d="M1 5h8" stroke="#000" stroke-width="1"/>
            <path v-else d="M1 5h8M5 1v8" stroke="#000" stroke-width="1"/>
          </svg>
        </div>
      </button>
      <div v-show="isVisible" class="collapsible-content">
        <slot></slot>
      </div>
    </div>
  </template>

  <script setup>
  import { ref } from 'vue';

  const { label, initialVisible } = defineProps({
    label: String,
    initialVisible: {
        type: Boolean,
        default: false,
    },
  });

  const emit = defineEmits(['visibilityChanged']);

  const isVisible = ref(initialVisible);

  const toggleVisibility = () => {
    isVisible.value = !isVisible.value;
    emit('visibilityChanged', isVisible.value);
  };
  </script>

  <style scoped>
  .collapsible-panel {
    width: 100%;
  }
  .button {
    width: 100%;
}
  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important; /* Ensure the button stretches to the container's width */
    padding: 0.5rem 1rem; /* Add some padding for better spacing */
    border: 2px solid black; /* Primary button color border */
    border-radius: 5px; /* Rounded corners for the button */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  }

  .button-container:hover {
    background-color: gray; /* Darken button on hover for feedback */
    }

.collapsible-content {
    border: 2px solid black;
    border-top: none; /* Remove the top border to merge visually with the button */
    padding: 1rem;
    border-radius: 0 0 5px 5px;
    margin-top: -2px; /* Pull content up to close the gap caused by the border */
}

.collapsible-panel {
    margin-bottom: 10px;
}

  .icon {
    width: 1em;
    height: 1em;
    margin-left: 1rem;
  }

/* @import '../../../../css/dynamic.css'
* this is in a different branch, should import the below styles
* instead once this gets rebased/merged
*/
.container {
    padding-right: 60px;
    padding-left: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: grid;
  }

  .middle {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .top {
    padding-bottom: 0px;
  }

  .bottom {
    padding-top: 0px;
  }

  .header-container {
    text-align: center;
  }

  .block-separator {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-right: 60px;
    padding-left: 60px;
  }

  </style>
