<template>
    <div class="splash">
        <header class="header-container">
            <div class="container mx-auto text-center relative logo-container-splash">
                <a href="/" aria-label="Mapping Color in History Home Page">
                    <img class="w-3/4 inline-block color-logo" :src="logo" alt="Mapping Color in History Logo">
                </a>
                <Hamburger class="inline-block absolute bottom-12 right-0" :open="showMenu" @toggle="toggleMenu" />
                
            </div>

        </header>
        
    </div>
</template>

<script>
import logo from '@public/images/color/color-logo-full.svg';

export default {
  data() {
    return {
      logo
    };
  }
}
</script>

<style lang="scss">
.full-width{
  width: 100%;;
}

</style>