<template>
  <div class="dynamic-block">
    <component :is="dynamicComponent.component" v-bind="dynamicComponent.props"></component>
  </div>
</template>

<script>
import CollectionCitationsBlock from './dynamic-blocks/CollectionCitationsBlock.vue';
import HeaderBlock from './dynamic-blocks/HeaderBlock.vue';
import NewsBlock from './dynamic-blocks/NewsBlock.vue';
import ProfileBlock from './dynamic-blocks/ProfileBlock.vue';
import SponsorBlock from './dynamic-blocks/SponsorBlock.vue';
import TextBlock from './dynamic-blocks/TextBlock.vue';

export default {
  components: {
    TextBlock,
    HeaderBlock,
    ProfileBlock,
    SponsorBlock,
    CollectionCitationsBlock,
    NewsBlock,
  },
  props: {
    block: Object,
    picUrlMap: Object,
    citationMap: Object,
    customSlug: {
      type: String,
      default: '',
    },
  },
  computed: {
    dynamicComponent() {
      let component = null;
      let props = {};

      switch (this.block.type) {
        case 'profile':
          component = ProfileBlock;
          props = { block: this.block };
          break;
        case 'sponsor':
          component = SponsorBlock;
          props = { block: this.block };
          break;
        case 'text':
          component = TextBlock;
          props = { block: this.block };
          break;
        case 'news':
          component = NewsBlock;
          props = { block: this.block, customSlug: this.customSlug };
          break;
        case 'collection_Citations':
          component = CollectionCitationsBlock;
          props = { block: this.block, citationMap: this.citationMap };
          break;
        case 'header':
          component = HeaderBlock;
          props = { block: this.block };
          break;
      }

      return { component, props };
    },
  },
};
</script>

<style scoped>
@import '../../../css/dynamic.css';
</style>
