<template>
    <div v-if="block.value.Zotero_Collection_ID">
        <div v-if="citationMap.get(block.value.Zotero_Collection_ID)" class="container">
          <div v-for="citation in citationMap.get(block.value.Zotero_Collection_ID)">
                <p :id="citation.zotKey" v-html="citation.citation"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    block: Object,
    citationMap: Object
  },
};
</script>

<style scoped>
@import '../../../../css/dynamic.css';
</style>
