<template>
  <div>
    <v-range-slider
      strict
      :min="min"
      :max="max"
      v-model="yearRange"
      :model-value="yearRange"
      step="50"
      thumb-label="always"
      @change="refine"
      @update:modelValue="onRangeUpdate"
    >
    </v-range-slider>
  </div>
</template>

<script>
export default {
  name: 'WorkDate',
  props: {
    defaultStartYear: {
      type: Number,
      default: null, // Default prop value
    },
    defaultEndYear: {
      type: Number,
      default: null, // Default prop value
    },
    min: {
      type: Number,
      default: 1000, // Minimum year allowed
    },
    max: {
      type: Number,
      default: 2000, // Maximum year allowed
    },
    resetTrigger: {
      type: Boolean,
      default: false,
    },
    resetData: {
      type: Object,
      default: () => ({}),
    }
  },
  methods: {
    refine() {
      // triggered when the range selection is explicitly changed by the user.
      this.$emit('yearRangeChanged', {
        startYear: this.yearRange[0],
        endYear: this.yearRange[1],
      });
    },
    onRangeUpdate(newValue) {
      // whenever the modelValue updates, not just on user change.
      this.$emit('yearRangeChanged', {
        startYear: newValue[0],
        endYear: newValue[1],
      });
    }
  },
  computed: {
    yearRange(){
      return [
        this.defaultStartYear !== null ? this.defaultStartYear : this.min,
        this.defaultEndYear !== null ? this.defaultEndYear : this.max,
      ];
    },
  }
};
</script>
