<template>
  <div class="container mx-auto mt-20 main-wrapper">
    <header>
      <h1 class="intro">Mapping Color in History<span class="superscript">&trade;</span> is a<br> <a href="/search" class="underline">searchable database</a> of pigment analysis in Asian paintings</h1>
    </header>

    <router-link :to="{ name: 'search-view' }">
      <div class="container mx-auto px-8 border-solid border-black border-2 text-center py-4 my-16 hover:bg-yellow">
          <span class="text-xl font-bold uppercase lspace-1">Search Works</span>
      </div>
    </router-link>

    <hr class="my-20">

    <div class="my-8 text-center">
      <h1 class="h1-title">Browse By</h1>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 justify-items-center text-center home-browse-container">
      <!-- Pigment -->
      <span class="w-52 h-auto relative">
        <router-link :to="{ name: 'pigments-view' }">
          <img :src="pigmentImage" @mouseover="pigmentHover = true" @mouseleave="pigmentHover = false" />
          <span class="absolute top-2/4 center-over-image text-xl font-bold uppercase align-middle lspace-1">Pigment</span>
        </router-link>
      </span>



      <!-- Color -->
      <span class="w-52 h-auto relative">
        <router-link :to="{ name: 'colors-view' }">
          <img :src="colorImage" @mouseover="colorHover = true" @mouseleave="colorHover = false"/>
          <span class="absolute top-2/4 center-over-image text-xl font-bold uppercase lspace-1">Color</span>
        </router-link>
      </span>



      <!-- Map -->
      <span class="w-52 h-auto relative">
        <router-link :to="{ name: 'search-view' }">
          <img :src="mapImage" @mouseover="mapHover = true" @mouseleave="mapHover = false" />
          <span class="absolute top-2/4 center-over-image text-xl font-bold uppercase lspace-1">Map</span>
        </router-link>
      </span>
    </div>

    <!-- MCH NEWS -->
    <hr class="mt-20 mb-2">
    <div class="news-container">
      <DynamicView customSlug='news'></DynamicView>
    </div>
    <hr class="my-8">



    <!-- VIRTUAL EXHIBITION same design as News, with orange button -->
    <div class="news-container virtual-exhibition">
      <DynamicView customSlug='exhibitions'></DynamicView>
    </div>

    <hr class="my-10">

  <!-- Sponsor Section  -->
    <section class="sponsor-container">
      <p class="h2-title center-full">Our Sponsors</p>
      <div class="sponsor-item">
          <img :src="barajasImage" />
      </div>
      <div class="sponsor-item">
          <img :src="deansCompetitiveFundImage" />
      </div>
      <div class="sponsor-item smaller">
          <img :src="mfaImage" />
      </div>
      <div class="sponsor-item">
          <img :src="hamImage" />
      </div>
      <div class="sponsor-item">
        <img :src="nehImage" />
      </div>
      <div class="sponsor-item">
          <img :src="haaImage" />
      </div>
      <div class="sponsor-item">
          <img :src="southAsiaImage" />
      </div>
    </section>

  </div>
</template>

<script>
  import colorDefaultImage from '@public/images/splash/icons/square_white.png';
  import colorHoverImage from '@public/images/splash/icons/square_blue.png';
  import mapHoverImage from '@public/images/splash/icons/circle_red.png';
  import mapDefaultImage from '@public/images/splash/icons/circle_white.png';
  import pigmentDefaultImage from '@public/images/splash/icons/blob_white.png';
  import pigmentHoverImage from '@public/images/splash/icons/blob_green.png';
  import thumbnailBorder from '@public/images/splash/icons/thumbnail_border.png';
  import exhibitionHoverImage from '@public/images/splash/icons/virtual_exhibition.png';
  import southAsiaImage from '@public/images/splash/sponsor_02.png';
  import barajasImage from '@public/images/splash/sponsor_03.png';
  import deansCompetitiveFundImage from '@public/images/splash/sponsor_04.png';
  import nehImage from '@public/images/splash/neh_bw.png';
  import haaImage from '@public/images/splash/sponsor_05.png';
  import mfaImage from '@public/images/splash/mfa.png';
  import hamImage from '@public/images/splash/ham.jpg';
  import DynamicView from './DynamicView.vue';


  export default {
    components: { DynamicView },
    data() {
      return {
        colorDefaultImage: colorDefaultImage,
        colorHoverImage: colorHoverImage,
        mapHoverImage: mapHoverImage,
        mapDefaultImage: mapDefaultImage,
        pigmentDefaultImage: pigmentDefaultImage,
        pigmentHoverImage: pigmentHoverImage,
        thumbnailBorder: thumbnailBorder,
        exhibitionHoverImage: exhibitionHoverImage,
        southAsiaImage: southAsiaImage,
        barajasImage: barajasImage,
        deansCompetitiveFundImage: deansCompetitiveFundImage,
        nehImage: nehImage,
        haaImage: haaImage,
        mfaImage: mfaImage,
        hamImage: hamImage,
        colorHover: false,
        mapHover: false,
        pigmentHover: false,
        notesHover: false,
        newsHover: false,
        exhibitionHover: false
      }
    },
    computed: {
      colorImage() {
        if(this.colorHover == true){
          return this.colorHoverImage;
        } else {
          return this.colorDefaultImage;
        }
      },
      mapImage(){
        if(this.mapHover == true){
          return this.mapHoverImage;
        } else {
          return this.mapDefaultImage;
        }
      },
      pigmentImage(){
        if(this.pigmentHover == true){
          return this.pigmentHoverImage;
        } else {
          return this.pigmentDefaultImage;
        }
      },
      notesImage(){
        if(this.notesHover == true){
          return this.notesImage;
        } else {
          return this.thumbnailBorder;
        }
      },
      newsImage(){
        if(this.newsHover == true){
          return this.newsImage;
        } else {
          return this.thumbnailBorder;
        }
      },
      exhibitionImage(){
        if(this.exhibitionHover == true){
          return this.exhibitionHoverImage;
        } else {
          return this.thumbnailBorder;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .center-over-image {
    transform: translate(-50%, -50%);
  }

  .hover\:.bg-yellow:hover {
    background-color: #faa400;
  }


</style>
