import "@fontsource/heebo";
import "../css/index.css";

import "../images/splash/c.png";
import "../images/splash/ham.jpg";
import "../images/splash/l.png";
import "../images/splash/mfa.png";
import "../images/splash/o.png";
import "../images/splash/o2.png";
import "../images/splash/r.png";
import "../images/splash/sponsor_02.png";
import "../images/splash/sponsor_03.png";
import "../images/splash/sponsor_04.png";
import "../images/splash/sponsor_05.png";

import app from "./app";

const ready = (fn) => {
  if (
    document.attachEvent
      ? document.readyState === "complete"
      : document.readyState !== "loading"
  ) {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
};

window.ready = ready;

app.mount("#app");
