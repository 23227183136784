<template>
  <div class="container mx-auto">
    <h1 class="h1-title">Browse by Colors</h1>
    <div class="flex flex-wrap mt-10" id="colors">
      <div class="mb-10 mx-4 color" :class="color.name" v-for="color in allColors" :key="color.uuid">
        <router-link :to="{ name: 'search-view', query: { 'works[refinementList][analyses.colors][0]': color.name }}">
          <ColorSwatch class="border-4 border-black w-44 h-44" :color="color" />
          <div class="text-center text-xl mt-2 color-name">
            {{ color.name }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';

  import ColorSwatch from '../components/ColorSwatch.vue';

  export default {
    components: { ColorSwatch },
    data() {
      return {
        allColors: [],
      }
    },
    apollo: {
      allColors: {
        query: gql`query Colors {
          allColors {
            edges {
              node {
                uuid
                name
                hexCode
                hexCodeLeftGradient
                hexCodeRightGradient
              }
            }
          }
        }`,
        update: data => data.allColors.edges.map(e => e.node),
      }
    }
  }
</script>
